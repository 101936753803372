import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { showAlert } from '../utils/helpers'

function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        if(email == "" || email == null){
            return showAlert({
                title: "Attention!",
                msg: "You have not entered any email",
                icon: "warning",
                btnText: "Close",
              });
        }

        return showAlert({
            title: "Success!",
            msg: "A password reset link has been sent to your email address",
            icon: "success",
            btnText: "Close",
          });
    }
  return (
    <div className="profile-authentication-area">
    <div className="d-table">
      <div className="d-table-cell">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <div className="row"> */}
          <div className="col-lg-6 col-lg-pull-3 col-md-6 col-md-pull-3 col-sm-12">
            <div className="login-form">
              <h2>Forgot Password?</h2>
              <form 
              onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={loading}
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
              
               
                <button type="submit" disabled={loading}>
                  {!loading ? "Reset" : "Loading..."}
                </button>
              </form>
              <div className="row align-items-center pt-4">
                <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                  <Link to="/login" className="lost-your-password">
                    Go back to Login
                  </Link>
                </div>
              </div>
            
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
    <Link className="back-icon" to="/">
      <i className="bx bx-x" />
    </Link>
  </div>
  )
}

export default ForgotPassword