import React, { useEffect, useState } from "react";
import { fetchAddress } from "../api/user.api";
import AddressTile from "../components/AddressTile";
import DashboardLayout from "../components/layouts/DashboardLayout";
import Loader from "../components/Loader";

function Address() {
  const [addresses, setAddresses] = useState({
    btc: "",
    eth: "",
    ltc: "",
    bch: "",
    xrp: "",
    ada: "",
    usdt: "",
    aave: "",
    pax: "",
    xlm: "",
    usdc: "",
    yfi: "",
    dodge: "",
    shiba: "",
    sol: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getAddress = async () => {
    try {
      setLoading(true);
      let resp = await fetchAddress();
      setLoading(false);
      if (!resp.status) return setError(resp.errors.msg);
      setAddresses(resp.value.wallets);
    } catch (err) {
      setLoading(false);
      setError("An error has occured");
    }
  };
  useEffect(() => {
    getAddress();
  }, []);

  return (
    <DashboardLayout>
      <div className="container-fluid">
        <div className="row pt-5">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <h5 className="card-title">Wallet Address</h5>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {loading && !addresses ? (
                    <Loader />
                  ) : (
                    <>
                      <AddressTile
                        title={"Bitcoin"}
                        value={addresses.btc}
                        tag={"BTC"}
                        image={
                          "https://iconarchive.com/download/i109476/cjdowner/cryptocurrency-flat/Bitcoin-BTC.ico"
                        }
                      />
                      <AddressTile
                        title={"Ethereum"}
                        value={addresses.eth}
                        tag={"ETH"}
                        image={
                          "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Ethereum-ETH-icon.png"
                        }
                      />
                      <AddressTile
                        title={"Litecoin"}
                        value={addresses.ltc}
                        tag={"LTC"}
                        image={
                          "https://s2.coinmarketcap.com/static/img/coins/200x200/2.png"
                        }
                      />
                      <AddressTile
                        title={"Bitcoin Cash"}
                        value={addresses.bch}
                        tag={"bch"}
                        image={
                          "https://iconarchive.com/download/i109477/cjdowner/cryptocurrency-flat/Bitcoin-Cash-BCH.ico"
                        }
                      />
                      <AddressTile
                        title={"Ripple"}
                        value={addresses.xrp}
                        tag={"XRP"}
                        image={
                          "https://assets-cdn.trustwallet.com/blockchains/ripple/info/logo.png"
                        }
                      />
                      <AddressTile
                        title={"Cardano"}
                        value={addresses.ada}
                        tag={"ADA"}
                        image={
                          "https://cdn.iconscout.com/icon/free/png-256/cardano-1852412-1569633.png"
                        }
                      />
                      <AddressTile
                        title={"Tether"}
                        value={addresses.usdt}
                        tag={"USDT"}
                        image={
                          "https://www.shareicon.net/download/2016/07/08/117379_tether.ico"
                        }
                      />
                      <AddressTile
                        title={"AAVE"}
                        value={addresses.aave}
                        tag={"AAVE"}
                        image={
                          "https://dappimg.com/media/image/token/a87d496ebd2c11eb8d1e0242ac130005.png"
                        }
                      />
                      <AddressTile
                        title={"PAX"}
                        value={addresses.pax}
                        tag={"PAX"}
                        image={
                          "https://s2.coinmarketcap.com/static/img/coins/200x200/3330.png"
                        }
                      />
                      <AddressTile
                        title={"Stellar"}
                        value={addresses.xlm}
                        tag={"XLM"}
                        image={
                          "https://icons-for-free.com/download-icon-stellar-1324440238144398100_256.icns"
                        }
                      />
                      <AddressTile
                        title={"USDC"}
                        value={addresses.usdc}
                        tag={"USDC"}
                        image={
                          "https://seeklogo.com/images/U/usd-coin-usdc-logo-CB4C5B1C51-seeklogo.com.png"
                        }
                      />
                      <AddressTile
                        title={"YFI"}
                        value={addresses.yfi}
                        tag={"yfi"}
                        image={
                          "https://images.youngplatform.com/coins/yearn_finance_light.png"
                        }
                      />
                      <AddressTile
                        title={"Shiba Inu"}
                        value={addresses.shiba}
                        tag={"SHIBA"}
                        image={
                          "https://cdn-icons-png.flaticon.com/128/4600/4600417.png"
                        }
                      />
                      <AddressTile
                        title={"Doge Coin"}
                        value={addresses.dodge}
                        tag={"DOGE"}
                        image={
                          "https://cdn.freebiesupply.com/logos/thumbs/2x/dogecoin-logo.png"
                        }
                      />
                      <AddressTile
                        title={"SOLANA"}
                        value={addresses.sol}
                        tag={"SOL"}
                        image={
                          "https://s2.coinmarketcap.com/static/img/coins/200x200/5426.png"
                        }
                      />
                    </>
                  )}
                </div>
                {/* end row */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Address;
