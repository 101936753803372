import React from 'react'
import { Link } from 'react-router-dom'

function Mobile() {
  return (
    
  <div className="app-download-area bg-f9f9f9">
  <div className="container">
    <div className="row align-items-center m-0">
      <div className="col-lg-6 col-md-12 p-0">
        <div className="app-download-image"><img src={require("../assets/images/app.png")} alt="image" /></div>
      </div>
      <div className="col-lg-6 col-md-12 p-0">
        <div className="app-download-content">
          <h2>Trade On The Go!</h2>
          <p>Our website is easy to use and responsive allowing you to trade and invest on the go from your mobile phone.</p>
          <div className="btn-box">
          <Link
              className="default-btn global-cursor"
              to="/register"
            >
              <i className="bx bxs-rocket" /> Start Trading
            </Link>
            {/* <a href="#" className="playstore-btn" target="_blank"><img src={require("../assets/images/play-store.png")} alt="image" />Get It On<span>Google Play</span></a>
            <a href="#" className="applestore-btn" target="_blank"><img src={require("../assets/images/apple-store.png")} alt="image" />Download on the<span>Apple Store</span></a> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Mobile