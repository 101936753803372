import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Navbar({showMenu}) {
  const location = useLocation();
  return (
    <nav className={` ${showMenu?"show":"hide-menu"} webnav navbar navbar-expand-md navbar-light`}>
    <div className="container">
    <div  style={{display:"flex",alignItems:"center"}}>
            <img src={require("../assets/images/mplogo.png")} alt="logo" />
            <Link to="/" className="d-inline-block logo">
              <span style={{fontSize:30,fontWeight:"bold"}}>Rapidus Invest</span>
              
              </Link>
            </div>
      <div className="collapse navbar-collapse mean-menu">
        <ul className="navbar-nav">
          {/* <li className="nav-item">
            <Link className={`nav-link ${location.pathname =='/'?"active":null}`} to="/">Home</Link>
          </li> */}
          <li className="nav-item megamenu">
            <a className="dropdown-toggle nav-link" href="#">Trade</a>
            <ul className="dropdown-menu">
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard/trade/btc"><img src={require("../assets/images/cryptocurrency/cryptocurrency2.png")} alt="image" />BTC - Bitcoin</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard/trade/ltc"><img src={require("../assets/images/cryptocurrency/cryptocurrency3.png")} alt="image" />LTC - Litecoin</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard/trade/xrp"><img src={require("../assets/images/cryptocurrency/cryptocurrency4.png")} alt="image" />XRP - Ripple</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard/trade/ste"><img src={require("../assets/images/cryptocurrency/cryptocurrency5.png")} alt="image" />STE - Stellar</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/register"><i className="bx bxs-chevron-right-circle" />View All Coins</Link>
              </li>
            </ul>
          </li>
          <li className="nav-item"><Link className={`nav-link listing  ${location.pathname =='/dashboard/trade'?"active":null}`} to="/dashboard/trade">Copy Trade</Link></li>
          <li className="nav-item"><Link className={`nav-link listing  ${location.pathname =='/about'?"active":null}`} to="/about">About</Link></li>
          <li className="nav-item"><Link className={`nav-link listing  ${location.pathname =='/contact'?"active":null}`} to="/contact">Contact</Link></li>
      
        </ul>
        <div className="others-option">
          <div className="d-flex align-items-center">
            <div className="option-item">
              <Link className="login-btn" to="/login"><i className="bx bx-log-in" /> Login</Link>
            </div>
            <div className="option-item">
              <Link className="default-btn" to="/register"><i className="bx bxs-rocket" /> Get Started</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  )
}

export default Navbar