import React, { useEffect, useState } from 'react'
import { fetchDeposits } from '../api/admin.api';
import AdminLayout from '../components/layouts/AdminLayout'
import Loader from '../components/Loader';
import { makeTitleCase, showAlert } from '../utils/helpers';

function AdminDeposits() {
    const [deposits, setDeposits] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(false)


    const getDeposits = async () => {
        try{
            setIsLoading(true)
            const resp = await fetchDeposits();
            // console.clear()
            // console.log(resp)
            setIsLoading(false)
            if(!resp.status) return ;
            setDeposits(resp.value)
        }
        catch(err){
            console.log(err)
            setErrors("An error has occured")
        }
    }


  useEffect(() => {
    if (errors && errors != null)
      showAlert({
        title: "Attention!",
        msg: errors,
      });
  }, [errors]);
    useEffect(() => {
     getDeposits();
    }, [])
    
  return (
    <AdminLayout>
    <div className="container-fluid">
        <div className="row">
           
        <div className="col-lg-12">
            <div className="card">
              <div className="card-header justify-content-between d-flex align-items-center">
                <h4 className="card-title"> Proof of Payment</h4>
              </div>
              {/* end card header */}
              <div className="card-body table-responsive">
                <table className="table mb-0 hovered stripped ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Uploaded on</th>
                      <th>Name</th>
                      <th colSpan={2}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                      {
                          isLoading && <Loader/>
                      }
                      {
                          !isLoading && deposits && deposits.map((item, key)=><tr>
                            <th scope="row">{key + 1}</th>
                            <td>{new Date(item.created).toDateString()}</td>
                            <td>{makeTitleCase(item.name)}</td>
                            <td>
                              {
                                  item.imageUrl? <a href={item.imageUrl} target="_blank" className="btn btn-primary btn-sm"> View Payment Proof  </a>:null
                              } 
                            </td>
                          </tr>) 
                      }
                    
                   
                  </tbody>
                </table>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>

        </div>
    </div>
    </AdminLayout>
  )
}

export default AdminDeposits