import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedAdminRoute from "./components/ProtectedAdminRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import About from "./screens/About";
import Address from "./screens/Address";
import AdminDashboard from "./screens/AdminDashboard";
import AdminDeposits from "./screens/AdminDeposits";
import AdminWithdrawals from "./screens/AdminWithdrawals";
import Contact from "./screens/Contact";
import Dashboard from "./screens/Dashboard";
import Deposit from "./screens/Deposit";
import ForgotPassword from "./screens/ForgotPassword";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Logout from "./screens/Logout";
import Profile from "./screens/Profile";
import Register from "./screens/Register";
import SingleUserUpdate from "./screens/SingleUserUpdate";
import Support from "./screens/Support";
import WalletAddress from "./screens/WalletAddress";
import Withdraw from "./screens/Withdraw";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <ProtectedRoute>
              <Deposit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/withdraw"
          element={
            <ProtectedRoute>
              <Withdraw />
            </ProtectedRoute>
          }
        />
        <Route
          path="/address"
          element={
            <ProtectedRoute>
              <Address />
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <Support />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboard />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/user-update/:uid"
          element={
            <ProtectedAdminRoute>
              <SingleUserUpdate />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/wallet-address"
          element={
            <ProtectedAdminRoute>
              <WalletAddress />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/user-deposits"
          element={
            <ProtectedAdminRoute>
              <AdminDeposits />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/user-withdrawals"
          element={
            <ProtectedAdminRoute>
              <AdminWithdrawals />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
