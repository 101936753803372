import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeTitleCase } from "../utils/helpers";
import { fetchUserBalance, getSingleUser, updateUserBalance } from "../api/admin.api";
import AdminLayout from "../components/layouts/AdminLayout";
import Loader from "../components/Loader";
import { showAlert } from "../utils/helpers";

function SingleUserUpdate() {
  const { uid } = useParams();
  console.log(uid);
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setloadingBalance] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(null);
  // console.log(balance)
  const [formdata, setFormdata] = useState({
      btc: balance?balance.btc:0,
      eth: balance?balance.eth:0,
      ltc: balance?balance.ltc:0,
      bch: balance?balance.bch:0,
      usdc: balance?balance.usdc:0,
      ada: balance?balance.ada:0,
      pax: balance?balance.pax:0,
      aave: balance?balance.aave:0,
      usdt: balance?balance.usdt:0,
      xlm: balance?balance.xlm:0,
      shiba: balance?balance.shiba:0,
      xrp: balance?balance.xrp:0,
      yfi: balance?balance.yfi:0,
      dodge: balance?balance.dodge:0,
      sol: balance?balance.sol:0|0,
      portfolio: balance?balance.portfolio:0,
      tax: balance?balance.tax:0,
  })
  // console.log(formdata)
  const getUser = async () => {
    try {
      setLoading(true);
      setError(null);
      const resp = await getSingleUser(uid);
      setLoading(false);
      if (!resp.status) {
        setError(resp.errors[0].msg);
        return;
      }
      setUser(resp.value);
    } catch (err) {
      // console.log(err);
      setError("A User error has occured");
    }
  };

  const getUserBalance = async () => {
    try {
      setloadingBalance(true);
      setError(null);
      const resp = await fetchUserBalance(uid);
      setloadingBalance(false);
      if (!resp.status) {
        setError(resp.errors[0].msg);
        return;
      }
      setBalance(resp.value);
      setFormdata({
        btc: resp.value.btc,
        eth: resp.value.eth,
        ltc: resp.value.ltc,
        bch: resp.value.bch,
        usdc: resp.value.usdc,
        ada: resp.value.ada,
        pax: resp.value.pax,
        aave: resp.value.aave,
        usdt: resp.value.usdt,
        xlm: resp.value.xlm,
        shiba: resp.value.shiba,
        xrp: resp.value.xrp,
        yfi: resp.value.yfi,
        dodge: resp.value.dodge,
        sol: resp.value.sol | 0,
        portfolio: resp.value.portfolio,
        tax: resp.value.tax,
    })
    } catch (err) {
      // console.log(err);
      setError("A Balance error has occured");
    }
  };

  const handleChange = (field, value) => {
    setFormdata({
        ...formdata, [field]: value
    })
  }

  const handleSubmit = async (e)=> {
      e.preventDefault();
      try{
          setIsSubmitting(true)
          setError(null)
        const resp = await updateUserBalance({uid, balance:formdata})
        setIsSubmitting(false)
        if(!resp.status){ setError(resp.errors[0].msg); return;}
        return showAlert({
            title: "Update Successful!",
            msg:"User balance has been updated successfully",
            icon: "success",
            btnText: "Close",
          });
      }
      catch(err){
          setIsSubmitting(false)
          setError("A submit error has occured");
      }
  }
  useEffect(() => {
    getUser();
    getUserBalance();
  }, []);

  useEffect(() => {
    if (error && error != null)
      showAlert({
        title: "Attention!",
        msg: error,
      });
  }, [error]);

  useEffect(() => {
    if (user) console.log(user);
    if (balance) console.log(balance);
  }, [user, balance]);

  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">
          {(!balance || !user) && (
            <>
              <Loader /> <h3>Loading...</h3>
            </>
          )}
          {user && balance && (
            <>
              <h4>Name: {makeTitleCase(user.name)}</h4>
              <p>Email: {user.email}</p>
              <p>Phone: {user.phone}</p>
              <p>Level: {user.level}</p>
              <p>Status: {user.status}</p>
              <p>Joined on : {new Date(user.created).toDateString()}</p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="btc"> BTC balance</label>
                      <input
                        type="number"
                        id="btc"
                        name="btc"
                        className="form-control input"
                        value={formdata.btc}
                        onChange={(e)=>handleChange("btc",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="eth"> ETH balance</label>
                      <input
                        type="number"
                        id="eth"
                        name="eth"
                        className="form-control input"
                        value={formdata.eth}
                        onChange={(e)=>handleChange("eth",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="ltc"> LTC balance</label>
                      <input
                        type="number"
                        id="ltc"
                        name="ltc"
                        className="form-control input"
                        value={formdata.ltc}
                        onChange={(e)=>handleChange("ltc",e.target.value)}
                      />
                    </div>
                  </div>

                </div>


                <div className="row">
                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="usdc"> USDC balance</label>
                      <input
                        type="number"
                        id="usdc"
                        name="usdc"
                        className="form-control input"
                        value={formdata.usdc}
                        onChange={(e)=>handleChange("usdc",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="ada"> Cardano (ADA) balance</label>
                      <input
                        type="number"
                        id="ada"
                        name="ada"
                        className="form-control input"
                        value={formdata.ada}
                        onChange={(e)=>handleChange("ada",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="pax"> PAX balance</label>
                      <input
                        type="number"
                        id="pax"
                        name="pax"
                        className="form-control input"
                        value={formdata.pax}
                        onChange={(e)=>handleChange("pax",e.target.value)}
                      />
                    </div>
                  </div>

                </div>


                <div className="row">
                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="aave"> AAVE balance</label>
                      <input
                        type="number"
                        id="aave"
                        name="aave"
                        className="form-control input"
                        value={formdata.aave}
                        onChange={(e)=>handleChange("aave",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="usdt"> USDT balance</label>
                      <input
                        type="number"
                        id="usdt"
                        name="usdt"
                        className="form-control input"
                        value={formdata.usdt}
                        onChange={(e)=>handleChange("usdt",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="xlm"> XLM balance</label>
                      <input
                        type="number"
                        id="xlm"
                        name="xlm"
                        className="form-control input"
                        value={formdata.xlm}
                        onChange={(e)=>handleChange("xlm",e.target.value)}
                      />
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="bch"> BCH balance</label>
                      <input
                        type="number"
                        id="bch"
                        name="bch"
                        className="form-control input"
                        value={formdata.bch}
                        onChange={(e)=>handleChange("bch",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="shiba"> SHIBA balance</label>
                      <input
                        type="number"
                        id="shiba"
                        name="shiba"
                        className="form-control input"
                        value={formdata.shiba}
                        onChange={(e)=>handleChange("shiba",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="xrp"> XRP balance</label>
                      <input
                        type="number"
                        id="xrp"
                        name="xrp"
                        className="form-control input"
                        value={formdata.xrp}
                        onChange={(e)=>handleChange("xrp",e.target.value)}
                      />
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="yfi"> YFI balance</label>
                      <input
                        type="number"
                        id="yfi"
                        name="yfi"
                        className="form-control input"
                        value={formdata.yfi}
                        onChange={(e)=>handleChange("yfi",e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="dodge"> DOGE balance</label>
                      <input
                        type="number"
                        id="dodge"
                        name="dodge"
                        className="form-control input"
                        value={formdata.dodge}
                        onChange={(e)=>handleChange("dodge",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="sol"> SOLANA balance</label>
                      <input
                        type="number"
                        id="sol"
                        name="sol"
                        className="form-control input"
                        value={formdata.sol}
                        onChange={(e)=>handleChange("sol",e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="portfolio"> Portfolio Signals</label>
                      <input
                        type="text"
                        id="portfolio"
                        name="portfolio"
                        className="form-control input"
                        value={formdata.portfolio}
                        onChange={(e)=>handleChange("portfolio",e.target.value)}
                      />
                    </div>
                  </div>
              

                </div>

              
                <div className="row">
                <div className="col-sm-4 pt-3 pb-3">
                    <div className="form-group">
                      <label htmlFor="tax"> TAX</label>
                      <input
                        type="text"
                        id="tax"
                        name="tax"
                        className="form-control input"
                        value={formdata.tax}
                        onChange={(e)=>handleChange("tax",e.target.value)}
                      />
                    </div>
                  </div>
                </div>

              

              

                <button disabled={isSubmitting} className="btn btn-lg btn-primary btn-block" type="submit">{!isSubmitting?"Submit":"Submitting..."}</button>
              </form>
            </>
          )}
        </div>
      </div>
    </AdminLayout>
  );
}

export default SingleUserUpdate;
