import React, { useEffect, useState } from "react";
import "../../assets/libs/jsvectormap/css/jsvectormap.min.css";
import "../../assets/libs/swiper/swiper-bundle.min.css";
// import "../../assets/css/bootstrap.min.css"
// import "../../assets/css/icons.min.css"
import "../../assets/css/app.min.css";
import { Link } from "react-router-dom";
// import { fetchUser, fetchUserBalance } from "../../api/user.api";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";
import Loader from "../Loader";
import SideMenu from "./SideMenu";
// import BalanceCard from "../BalanceCard";

const AdminLayout = ({ children }) => {
  const [showNav, setShowNav] = useState(false)
  return (
    <>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              {/* LOGO */}
              <div className="navbar-brand-box">
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 header-item vertical-menu-btn noti-icon"
                onClick={()=>setShowNav(!showNav)}
              >
                <i className="fa fa-fw fa-bars font-size-16" />
              </button>

              <div className="topnav">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                  <div
                    className={`collapse navbar-collapse ${showNav?"show":""}`}
                    id="topnav-menu-content"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin-dashboard"
                          id="topnav-dashboard"
                        >
                          <i className="bx bx-home-circle icon" />
                          <span data-key="t-dashboard">Dashboard</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/wallet-address"
                          id="topnav-uielement"
                        >
                          <i className="bx bx-bitcoin icon" />
                          <span data-key="t-elements">Wallet Address</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/user-deposits"
                          id="topnav-pages"
                        >
                          <i className="bx bx-money icon" />
                          <span data-key="t-apps">Deposits</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/user-withdrawals"
                          id="topnav-components"
                        >
                          <i className="bx bx-wallet icon" />
                          <span data-key="t-components">Withdrawals</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/logout"
                          id="topnav-more"
                        >
                          <i className="bx bx-log-out-circle icon" />
                          <span data-key="t-pages">Logout</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>


             
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  className="btn header-item user text-start d-flex align-items-center"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src="assets/images/users/avatar-3.jpg"
                    alt="Header Avatar"
                  />
                  <span className="ms-2 d-none d-xl-inline-block user-item-desc">
                    <span className="user-name">
                    Administrator<i className="mdi mdi-chevron-down" />
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end pt-0">
                  <h6 className="dropdown-header">Administrator</h6>
                  <Link className="dropdown-item" to="/users-deposits">
                    <span className="align-middle">Deposits</span>
                  </Link>
                  <Link className="dropdown-item" to="/users-withdrawals">
                    <span className="align-middle">Withdrawals</span>
                  </Link>
                 
                  <div className="dropdown-divider" />
                  
                  {/* <Link className="dropdown-item" to="/update-user-balance">
                    <span className="align-middle">Update Balance</span>
                  </Link> */}
                  <Link className="dropdown-item" to="/logout">
                    <span className="align-middle">Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="vertical-menu">
          {/* LOGO */}
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <div data-simplebar className="sidebar-menu-scroll">
            {/*- Sidemenu */}
            <SideMenu/>
            {/* Sidebar */}
          </div>
        </div>

        <div className="main-content" style={{marginLeft:255}}>
          <div class="page-content">
          {children}
          </div>

          
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
