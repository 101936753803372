import React, { useEffect, useState } from "react";
import "../../assets/libs/jsvectormap/css/jsvectormap.min.css";
import "../../assets/libs/swiper/swiper-bundle.min.css";
// import "../../assets/css/bootstrap.min.css"
// import "../../assets/css/icons.min.css"
import "../../assets/css/app.min.css";
import { Link } from "react-router-dom";
import { fetchUser, fetchUserBalance } from "../../api/user.api";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";
import Loader from "../Loader";
import BalanceCard from "../BalanceCard";

const DashboardLayout = ({ children }) => {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [error, setError] = useState(null);

  const getUserDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      let resp = await fetchUser();
      setLoading(false);
      if (resp.errors) {
        setError(resp.errors.msg);
        return false;
      }
      //   console.log(resp.value);
      setUser(resp.value);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("An error has occured ");
    }
  };
  const getUserBalance = async () => {
    try {
      setLoadingBalance(true);
      const resp = await fetchUserBalance();
      setLoadingBalance(false);
      if (resp.errors) {
        setError(resp.errors.msg);
        return false;
      }
      setBalance(resp.value);
    } catch (err) {
      setError("An error has occured ");
    }
  };

  useEffect(() => {
    if (error && error != null) showAlert("Attention!", error);
  }, [error]);

  useEffect(() => {
    getUserDetails();
    getUserBalance();
  }, []);

  let name = user ? makeTitleCase(user.name) : " ";

  return (
    <>
      <div id="layout-wrapper">
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              {/* LOGO */}
              <div className="navbar-brand-box">
               
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-bs-toggle="collapse"
                id="horimenu-btn"
                data-bs-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
              <div className="topnav">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                  <div
                    className="collapse navbar-collapse"
                    id="topnav-menu-content"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/dashboard"
                          id="topnav-dashboard"
                        >
                          <i className="bx bx-home-circle icon" />
                          <span data-key="t-dashboard">Dashboard</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/deposit"
                          id="topnav-uielement"
                        >
                          <i className="bx bx-building icon" />
                          <span data-key="t-elements">Deposit</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/withdraw"
                          id="topnav-pages"
                        >
                          <i className="bx bx-wallet icon" />
                          <span data-key="t-apps">Withdraw</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/address"
                          id="topnav-components"
                        >
                          <i className="bx bx-bitcoin icon" />
                          <span data-key="t-components">Address</span>
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link"
                          to="/support"
                          id="topnav-more"
                        >
                          <i className="bx bx-cog icon" />
                          <span data-key="t-pages">Support</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block">
                <button
                  type="button"
                  className="btn header-item user text-start d-flex align-items-center"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src={require("../../assets/images/user/user.png")}
                    alt="User"
                  />
                  <span className="ms-2 d-none d-xl-inline-block user-item-desc">
                    <span className="user-name">
                      {name} <i className="mdi mdi-chevron-down" />
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end pt-0">
                  <Link className="dropdown-item" to="/profile">
                    <span className="align-middle">Profile</span>
                  </Link>
                  <Link className="dropdown-item" to="/deposit">
                    <span className="align-middle">Deposit</span>
                  </Link>
                  <Link className="dropdown-item" to="/withdraw">
                    <span className="align-middle">Withdraw</span>
                  </Link>
                  <div className="dropdown-divider" />
                  <Link className="dropdown-item" to="/support">
                    <span className="align-middle">Help</span>
                  </Link>
                  <Link className="dropdown-item" to="/logout">
                    <span className="align-middle">Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="collapse dash-content" id="dashtoggle">
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">Hello {name.split(" ")[0]} !</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              {/* start dash info */}
              <div className="row">
                <div className="col-xl-12">
                  <div className="card dash-header-box shadow-none border-0">
                    <div className="card-body p-0">
                      <div className="row row-cols-xxl-6 row-cols-md-3 row-cols-1 g-0">
                        {loadingBalance ? (
                          <div
                            style={{
                              padding: 50,
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Loader />
                          </div>
                        ) : (
                          <>
                            <BalanceCard
                              title="Bitcoin Balance"
                              value={balance ? balance.btc : 0}
                            />
                            <BalanceCard
                              title="Ethereum Balance"
                              value={balance ? balance.eth : 0}
                            />
                            <BalanceCard
                              title="Litecoin Balance"
                              value={balance ? balance.ltc : 0}
                            />
                            <BalanceCard
                              title="Bitcoin Cash"
                              value={balance ? balance.bch : 0}
                            />
                            <BalanceCard
                              title="XRP Balance"
                              value={balance ? balance.xrp : 0}
                            />
                            <BalanceCard
                              title="Cardano Balance"
                              value={balance ? balance.ada : 0}
                            />
                            <BalanceCard
                              title="Tether Balance"
                              value={balance ? balance.usdt : 0}
                            />
                            <BalanceCard
                              title="Shiba Balance"
                              value={balance ? balance.shiba : 0}
                            />
                            <BalanceCard
                              title="USD Coin Balance"
                              value={balance ? balance.usdc : 0}
                            />
                            <BalanceCard
                              title="YFI Balance"
                              value={balance ? balance.yfi : 0}
                            />
                            <BalanceCard
                              title="Doge Balance"
                              value={balance ? balance.dodge?balance.dodge:0 : 0}
                            />
                            <BalanceCard
                              title="AAVE Balance"
                              value={balance ? balance.aave?balance.aave:0 : 0}
                            />
                            <BalanceCard
                              title="XLM Balance"
                              value={balance ? balance.xlm?balance.xlm:0 : 0}
                            />
                            <BalanceCard
                              title="SOLANA Balance"
                              value={balance ? balance.sol?balance.sol:0 : 0}
                            />
                            <BalanceCard
                              title="Portfolio Signal"
                              value={balance ? balance.portfolio : "UNPAID"}
                            />
                            <BalanceCard
                              title="Tax"
                              value={balance ? balance.tax : "UNPAID"}
                            />
                            <BalanceCard
                              title="Portfolio Increase"
                              value={balance && balance.increase ? `${balance.increase}%`: "0%"}
                            />
                          </>
                        )}
                      </div>
                      {/* end row */}
                    </div>
                    {/* end card body */}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
              </div>
              {/* end dash info */}
            </div>
          </div>
          {/* start dash troggle-icon */}
          <div>
            <a
              className="dash-troggle-icon"
              id="dash-troggle-icon"
              data-bs-toggle="collapse"
              href="#dashtoggle"
              aria-expanded="true"
              aria-controls="dashtoggle"
              style={{ marginTop: -65 }}
            >
              <i className="bx bx-up-arrow-alt" />
            </a>
          </div>
          {/* end dash troggle-icon */}
        </header>
        <div className="main-content">
          {/* <div class="page-content"> */}
            {children}
            {/* </div> */}

          {/* <footer class="footer">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  {new Date().getFullYear()} &copy; Rapidus Invest.
                </div>
                <div class="col-sm-6">
                  <div class="text-sm-end d-none d-sm-block">
                    Crafted with <i class="mdi mdi-heart text-danger"></i>
                  </div>
                </div>
              </div>
            </div>
          </footer> */}
          
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
