import React from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";

function Support() {
  return (
    <DashboardLayout>
      <div classname="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row g-2">
                  <div className="col-lg-auto">
                    <div className="d-flex">
                      <div className="avatar flex-shrink-0 me-3">
                        <div className="avatar-title bg-light rounded-circle">
                          <img
                            src={require("../assets/images/mplogo.png")}
                            alt
                            height={28}
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-size-16 mb-1">
                        Rapidus Invest Customer Support
                        </h5>
                        <p className="text-muted mb-0">
                        You can contact us for inquiries or to resolve any issues you may be having using our platform by sending a mail to <a href="mailto:support@rapidusinvest.com">support@rapidusinvest.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto ms-sm-auto">
                    <div className="avatar-group justify-content-sm-end">
                      <div className="avatar-group-item">
                        <a
                          href="javascript: void(0);"
                          className="d-block"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Emily Surface"
                        >
                          <div className="avatar">
                            <div className="avatar-title rounded-circle bg-primary">
                              E
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* end avatar group item */}
                      <div className="avatar-group-item">
                        <a
                          href="javascript: void(0);"
                          className="d-block"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="James Scott"
                        >
                          <div className="avatar">
                            <img
                              src="assets/images/users/avatar-2.jpg"
                              alt
                              className="img-fluid rounded-circle"
                            />
                          </div>
                        </a>
                      </div>
                      {/* end avatar group item */}
                      <div className="avatar-group-item">
                        <a
                          href="javascript: void(0);"
                          className="d-block"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Lynn Hackett"
                        >
                          <div className="avatar">
                            <div className="avatar-title rounded-circle bg-info">
                              L
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* end avatar group item */}
                      <div className="avatar-group-item">
                        <a
                          href="javascript: void(0);"
                          className="d-block"
                          data-bs-toggle="tooltip"
                          data-placement="top"
                          title="Add New"
                        >
                          <div className="avatar">
                            <div className="avatar-title rounded-circle bg-light text-primary">
                              <i className="mdi mdi-plus fs-5" />
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* end avatar group item */}
                    </div>
                    {/* end avatar-group */}
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
              </div>
              {/*end card-body*/}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Support;
