import axios from "axios";
import { API_URL } from "../configs/app";
import { setAdminHeader } from "../utils/helpers";


export const getAllUsers = async () => {
    try{
        let header = await setAdminHeader();
        const res = await axios.get(`${API_URL}/admin/users`, header)
        return res.data
        }
        catch(err){
            return {
                error:true,
                msg:err
            }
        }
}

export const getSingleUser = async uid => {
    try{
        let header = await setAdminHeader();
        const res = await axios.get(`${API_URL}/admin/singleusers/${uid}`, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchUserBalance = async uid => {
    try{
        let header = await setAdminHeader();
        const res = await axios.get(`${API_URL}/admin/userbalance/${uid}`, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const updateUserBalance = async (data) => {
    try{
        let header = await setAdminHeader();
        const res = await axios.post(`${API_URL}/admin/update-user`,data,  header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const deleteUser = async id => {
    try{
        let header = await setAdminHeader();
        const res = await axios.post(`${API_URL}/admin/delete-user`,{id},  header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const adminWallets = async () => {
    try{
        let header = await setAdminHeader();
        const res = await axios.get(`${API_URL}/users/address`, header)
        return res.data
    }catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const saveWalletAddress = async (data) => {
    try{
        let header = await setAdminHeader();
        const res = await axios.post(`${API_URL}/admin/save-address`, data, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchDeposits = async () => {
    try{
        let header = await setAdminHeader();
        const res = await axios.get(`${API_URL}/admin/user-deposit`, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchWithdrawals = async () => {
    try{
        let header = await setAdminHeader();
        const res = await axios.get(`${API_URL}/admin/user-withdrawals`, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}