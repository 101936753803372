import React from "react";
import { Link } from "react-router-dom";
import CreationProcess from "../components/CreationProcess";
import FeaturesStep from "../components/FeaturesStep";
import Feedback from "../components/Feedback";
import FunFacts from "../components/FunFacts";
import Jumbotron from "../components/Jumbotron";
import WebsiteLayout from "../components/layouts/WebsiteLayout";
import Mobile from "../components/Mobile";
import Reasons from "../components/Reasons";

function Home() {
  return (
    <WebsiteLayout>
      <Jumbotron />
      <FunFacts />
      <Reasons />
      <CreationProcess />
      <Feedback />
      <div className="portfolio-area bg-f9f9f9">
        <div className="container">
          <div className="single-portfolio-item">
            <div className="row align-items-center m-0">
              <div className="col-xl-12 col-lg-12 col-md-12 p-0">
                <div className="image text-center">
                  <img
                    src={require("../assets/images/portfolio/portfolio-img1.png")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeaturesStep />
      <Mobile />
      <div className="account-register-area ptb-100">
        <div className="container">
          <div className="account-register-content">
            <h2>Start Trading on Rapidus Invest</h2>
            <p>
            We've helped over 10,000 people discover smarter investing in multiple types of assets. Build your future.
            </p>
            <Link
              className="default-btn global-cursor"
              to="/register"
            >
              <i className="bx bxs-rocket" /> Get Started
            </Link>
          </div>
        </div>
        <div className="shape6">
          <img src={require("../assets/images/shape/shape6.png")} alt="image" />
        </div>
        <div className="shape7">
          <img src={require("../assets/images/shape/shape7.png")} alt="image" />
        </div>
        <div className="shape8">
          <img src={require("../assets/images/shape/shape8.png")} alt="image" />
        </div>
        <div className="shape9">
          <img src={require("../assets/images/shape/shape9.png")} alt="image" />
        </div>
        <div className="shape10">
          <img
            src={require("../assets/images/shape/shape10.png")}
            alt="image"
          />
        </div>
      </div>
    </WebsiteLayout>
  );
}

export default Home;
