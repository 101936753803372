import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cookieGet } from "../utils/cookie";

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  let history = useNavigate();
  const checkAuth = async () => {
    const val = await cookieGet("moonpy_token");
    if (!val) {
      return history(redirectPath);
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);

  return children;
};

export default ProtectedRoute;
