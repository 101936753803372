import React from 'react'
import WebsiteLayout from '../components/layouts/WebsiteLayout'

function Contact() {
  return (
    <WebsiteLayout>
  <div className="contact-area ptb-100">
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-lg-12 col-md-12">
          <div className="contact-info">
            <h3>Contact Us:</h3>
            <p>Contact us with your details &amp; ready to start with us. Get In Touch!</p>
            <ul className="contact-list">
              {/* <li>
                <div className="icon"><i className="bx bx-support" /></div>
                <p><a href="tel:+44587154756">+44 1223 926945</a></p>
              </li> */}
              <li>
                <div className="icon"><i className="bx bx-envelope" /></div>
                <p style={{}}>
                <a href="mailto:support@rapidusinvest.com">support@<br/>rapidusinvest.com</a>
                </p>
              </li>
              <li>
                <div className="icon"><i className="bx bx-map" /></div>
                <p> 93 Green Lane Cambridge, CB78 9JX, United Kingdom</p>
              </li>
            </ul>
           
          </div>
        </div>
        <div className="col-xl-8 col-lg-12 col-md-12">
          <div className="maps">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9782.293967804173!2d0.11693804834348373!3d52.1966249478628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d85d89f32a012d%3A0x63a320e1a35e3d21!2sCambridge%2C%20UK!5e0!3m2!1sen!2sbd!4v1657195412150!5m2!1sen!2sbd"/>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <div className="section-title">
          <h2>Get In Touch!</h2>
          <p>Leave us a message and we will reach out to you immediately.</p>
        </div>
        <form id="contactForm">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="form-group">
                <input type="text" name="name" className="form-control" id="name" required data-error="Please enter your name" placeholder="Eg: Sarah Taylor" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="form-group">
                <input type="email" name="email" className="form-control" id="email" required data-error="Please enter your email" placeholder="hello@sarah.com" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="form-group">
                <input type="text" name="phone_number" className="form-control" id="phone_number" required data-error="Please enter your phone number" placeholder="Enter your phone number" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="form-group">
                <input type="text" name="msg_subject" className="form-control" id="msg_subject" placeholder="Enter your subject" required data-error="Please enter your subject" />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group">
                <textarea name="message" id="message" className="form-control" cols={30} rows={6} required data-error="Please enter your message" placeholder="Enter message..." defaultValue={""} />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <button type="submit" className="default-btn"><i className="bx bx-paper-plane" /> Send Message</button>
              <div id="msgSubmit" className="h3 text-center hidden" />
              <div className="clearfix" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
    </WebsiteLayout>
  )
}

export default Contact