import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { TickerTape } from "react-ts-tradingview-widgets";

const WebsiteLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const symbols = [
    {
      "title": "BTC/USD",
      "proName": "BINANCE:BTCUSD"
    },
    {
      "title": "BTC/USDT",
      "proName": "BINANCE:BTCUSDT"
    },
    {
      "title": "ETH/USDT",
      "proName": "BINANCE:ETHUSDT"
    },
    {
      "title": "BNB/USDT",
      "proName": "BINANCE:BNBUSDT"
    },
    {
      "title": "XRP/USDT",
      "proName": "BINANCE:XRPUSDT"
    },
    {
      "title": "LTC/USDT",
      "proName": "BINANCE:LTCUSDT"
    },
    {
      "title": "DOGE/USDT",
      "proName": "BINANCE:DOGEUSDT"
    },
    {
      "title": "SOL/USD",
      "proName": "BINANCE:SOLUSD"
    },
    {
      "title": "BTC/USD",
      "proName": "COINBASE:BTCUSD"
    },
    {
      "title": "ETH/USD",
      "proName": "COINBASE:ETHUSD"
    },
    {
      "title": "ADA/USD",
      "proName": "COINBASE:ADAUSD"
    },
    {
      "title": "LTC/USD",
      "proName": "COINBASE:LTCUSD"
    },
    {
      "title": "DOGE/USD",
      "proName": "COINBASE:DOGEUSD"
    },
    {
      "title": "SOL/USD",
      "proName": "COINBASE:SOLUSD"
    },
  ]

  return (
    <div id="__next">
      <div className="value-trade-area">
        <TickerTape
          colorTheme="light"
          symbols={symbols}
          isTransparent={true}
          copyrightStyles={{
            parent: {
              display: "none",
            },
          }}
        ></TickerTape>
      </div>

      <div id="navbar" className="navbar-area">
        <div className="raimo-responsive-nav">
          <div className="container">
            <div className="raimo-responsive-menu">
              <div
                className="hamburger-menu"
                onClick={() => setShowMenu(!showMenu)}
              >
                <i className="bx bx-menu" />
              </div>
              <div className="logo" style={{display:"flex", alignItems:"center"}}>
                <Link className="d-inline-block logo" to="/" style={{width:"unset"}}>
                  <img
                    src={require("../../assets/images/mplogo.png")}
                    alt="logo"
                  />
                </Link>
                <span style={{fontSize:18,fontWeight:"bold", marginLeft:5}}>Rapidus Invest</span>
              </div>
            </div>
          </div>
        </div>
        <Navbar showMenu={showMenu} />
      </div>

      {children}
      <Footer />
    </div>
  );
};

export default WebsiteLayout;
