import Cookies from 'js-cookie'
import {APP_NAME} from '../configs/async';
import {encrypto, decrypto} from './encryption';

/**
 * Saves data to the async store
 * @param {string} key
 * @param {any} data
 * @return promise
 */
export const cookieStore = async function(key, data) {
    return await Cookies.set(APP_NAME + key, encrypto(data), { expires: 7 })
};

export const cookieGet = async function(key) {
  // get data from the cookies storage
  var data = await Cookies.get(APP_NAME + key);
  // only decrypt data if found
  if (data !== undefined && data !== null) {
    data = decrypto(data);
  }
  // return data
  return data;
};

/**
 * Removes saved data from the async store
 * @param {string} key
 * @return Promise
 */
export const cookieRemove = async function(key) {
  return await Cookies.remove(APP_NAME + key);
};
