import React, { useState } from "react";
import { createDeposit } from "../api/user.api";
import DashboardLayout from "../components/layouts/DashboardLayout";
import { showAlert } from "../utils/helpers";

function Deposit() {
const [formdata, setFormdata] = useState({
    image:""
})
const [isLoading, setIsLoading] = useState(false)
  const handleChange = (field, value) => {
    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };


  const handleSubmit = async (e) => {
    try{
      e.preventDefault();

      if(formdata.image == "" || formdata.image == null){
        return showAlert({
          title: "Attention!",
          msg: "You have not selected an image",
          icon: "warning",
          btnText: "Close",
        });
      }

      setIsLoading(true)
      let payload = new FormData();
      payload.append("image", formdata.image);
      //make API request
      const resp = await createDeposit(payload);
      setIsLoading(false)
      if (!resp.status){
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      setFormdata({image:""})
      return showAlert({
        title: "Thank you!",
        msg: `An admin will revert to you shortly`,
        icon: "success",
        btnText: "Close",
      });
    }
    catch(err){
      console.log(err);
      setIsLoading(false)
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
 
};

  return (
    <DashboardLayout>
        <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="justify-content-between d-flex align-items-center mt-3 mb-4">
            <h2 className="mb-0 pb-1 text-decoration-underline">
              Make a Deposit
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card card-body">
                <h5 className="card-title mb-1">Steps to make a deposit</h5>
                <p className="card-text">
                  <ol>
                      <li>Open your favourite cryptocurrency exchange platform eg 
                          <a target="_blank" href="https://crypto.com"> Crypto.com</a>,
                          <a target="_blank" href="https://www.coinbase.com/"> Coinbase.com</a>
                          <a target="_blank" href="https://www.binance.com/"> Binance.com</a> etc...
                          </li>

                          <li>
                            Choose the currency you want to transfer to eg BTC, ETH, LTC ...
                        </li>

                        <li>Enter an amount</li>
                        <li> Enter the wallet address of the currency you are making a deposit to</li>
                        <li>Complete the transfer </li>
                        <li>Take a screenshot or download a receipt of the payment </li>
                        <li>Come back to your Rapidus Invest account, under Deposit and click on "Upload proof of payment"</li>
                        <li>Upload the Proof of Payment and your account will be funded accordingly</li>
                  </ol>
                </p>
                
              </div>
            </div>
            {/* end col */}
            <div className="col-md-6">
              <div className="card card-body">
                <h5 className="card-title mb-1">Already made the transfer? Upload Proof of Payment </h5>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                <p className="card-text">
                  <input type="file" onChange={(e) => handleChange("image", e.target.files[0])}/>
                <button disabled={isLoading} type="submit" className="btn btn-primary" onClick={handleSubmit}>
                  {!isLoading?"Upload":"Uploading..."}
                </button>
                </p>
                </form>
              </div>
            </div>
          </div>
          {/* end row */}
        </div>
        {/* end col */}
      </div>
      {/* end row */}
      </div>
    </DashboardLayout>
  );
}

export default Deposit;
