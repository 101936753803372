import React from 'react'
import { Link } from 'react-router-dom'

function SideMenu() {
  return (
    <div id="sidebar-menu">
    {/* Left Menu Start */}
    <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title" data-key="t-menu">
        Menu
      </li>
      <ListItem name="Dashboard" address="/admin-dashboard" icon="bx-home-circle"/>
      {/* <ListItem name="Update Balance" address="/update-user-balance" icon="bx-user"/> */}
      <ListItem name="Wallet Addresses" address="/wallet-address" icon="bx-bitcoin"/>
      <ListItem name="Deposits" address="/user-deposits" icon="bx-money"/>
      <ListItem name="Withdrawals" address="/user-withdrawals" icon="bx-wallet"/>
      <ListItem name="Logout" address="/logout" icon="bx-log-out-circle"/>
      {/* <ListItem name="" address="" icon=""/> */}
    </ul>
  </div>
  )
}

const ListItem = ({name, address, icon}) => {
return (
<li>
        <Link to={address}>
          <i className={`bx ${icon} nav-icon`} />
          <span className="menu-item">
            {name}
          </span>
        </Link>
      </li>
)
}
//bx-home-circle
//t-dashboard
export default SideMenu