import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { cookieGet } from "../utils/cookie";
import { CURRENCY_SIGN } from "../configs/app";

const MySwal = withReactContent(Swal);

export const showAlert = ({ title, msg, icon="error", btnText="close" }) =>
  MySwal.fire({
    title,
    text: msg,
    icon,
    confirmButtonText: btnText,
  });

export const setHeader = async () => {
  let token = await cookieGet("moonpy_token");
  return {
    headers: {
      token,
    },
  };
};
export const setAdminHeader = async () => {
  let token = await cookieGet("moonpy_admin_token");
  return {
    headers: {
      token,
    },
  };
};

export const thousand = (amount) => {
  // convert number to string
  amount = String(amount);
  // return amount string with commas after every three characters
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const displayAmount = (
  amount, pad, sign = CURRENCY_SIGN,
) => {
  // return number string with commas after every three characters
  return `${sign}${thousand(Number(amount).toFixed(pad)).trim()}`;
};

export const makeTitleCase = str => {
  if (!str || str.length < 1) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}


export function parseTimeDate(date) {
  const dateSplit = date.split('T');
  if (dateSplit.length < 2) {
    return date;
  }
  const time = dateSplit[1].split(':');
  let ampm = 'am';
  let hour = Number(time[0]);
  let minute = Number(time[1]);
  if (hour - 12 >= 0) {
    hour = hour == 12 ? hour : hour - 12;
    ampm = 'pm';
  }
  return padNumber(hour) + ':' + padNumber(minute) + ampm;
}


export const padNumber = (amount, maxLength = 2) => {
  const num = String(amount);
  if (num.length >= maxLength) {
    return num;
  }
  // add zero to number
  amount = `0${amount.toString().trim()}`;
  if (amount.length < maxLength) {
    return padNumber(amount, maxLength);
  }
  // return amount
  return amount;
};