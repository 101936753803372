import React,{useState, useEffect} from "react";
import { adminWallets, saveWalletAddress } from "../api/admin.api";
import AdminLayout from "../components/layouts/AdminLayout";
import { showAlert } from "../utils/helpers";

function WalletAddress() {
const [wallets, setWallets] = useState(null)
const [isSubmitting, setIsSubmitting] = useState(false)
const [formdata, setFormdata] = useState({
    btc: wallets?wallets.btc:"XXXXX",
    eth: wallets?wallets.eth:"XXXXX",
    ltc: wallets?wallets.ltc:"XXXXX",
    bch: wallets?wallets.bch:"XXXXX",
    usdc: wallets?wallets.usdc:"XXXXX",
    ada: wallets?wallets.ada:"XXXXX",
    pax: wallets?wallets.pax:"XXXXX",
    aave: wallets?wallets.aave:"XXXXX",
    usdt: wallets?wallets.usdt:"XXXXX",
    xlm: wallets?wallets.xlm:"XXXXX",
    shiba: wallets?wallets.shiba:"XXXXX",
    xrp: wallets?wallets.xrp:"XXXXX",
    yfi: wallets?wallets.yfi:"XXXXX",
    dodge: wallets?wallets.dodge?wallets.dodge:"XXXXX":"XXXXX",
    sol: wallets?wallets.sol?wallets.sol:"XXXXX":"XXXXX",
})
    const getWallets = async () => {
        try{
            const resp = await adminWallets();
            if(!resp.status) return;
            setWallets(resp.value.wallets)
            setFormdata({
                btc: resp.value.wallets.btc,
                eth: resp.value.wallets.eth,
                ltc: resp.value.wallets.ltc,
                bch: resp.value.wallets.bch,
                usdc: resp.value.wallets.usdc,
                ada: resp.value.wallets.ada,
                pax: resp.value.wallets.pax,
                aave: resp.value.wallets.aave,
                usdt: resp.value.wallets.usdt,
                xlm: resp.value.wallets.xlm,
                shiba: resp.value.wallets.shiba,
                xrp: resp.value.wallets.xrp,
                yfi: resp.value.wallets.yfi,
                dodge: resp.value.wallets.dodge,
                sol: resp.value.wallets.sol,
            })
        }
        catch(err){
            // console.log(err)
        }
    }


  const handleChange = (field, value) => {
    setFormdata({
        ...formdata, [field]: value
    })
  }

const handleSubmit = async (e) => {
    e.preventDefault();
    try{
        setIsSubmitting(true)
            const resp = await saveWalletAddress({...formdata});
            setIsSubmitting(false)
            if(!resp.status) return;
            return showAlert({
                title: "Update Successful!",
                msg:"Wallet has been updated successfully",
                icon: "success",
                btnText: "Close",
              });

    }
    catch(err){
        // console.log(err)
        setIsSubmitting(false)
    }

}

    useEffect(() => {
      getWallets()
    }, [])
    
  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">
            <h3 className="title">Wallet Address</h3>


        {
            wallets &&   <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="btc"> BTC Address</label>
                  <input
                    type="text"
                    id="btc"
                    name="btc"
                    className="form-control input"
                    value={formdata.btc}
                    onChange={(e)=>handleChange("btc",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="eth"> ETH Address</label>
                  <input
                    type="text"
                    id="eth"
                    name="eth"
                    className="form-control input"
                    value={formdata.eth}
                    onChange={(e)=>handleChange("eth",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="ltc"> LTC Address</label>
                  <input
                    type="text"
                    id="ltc"
                    name="ltc"
                    className="form-control input"
                    value={formdata.ltc}
                    onChange={(e)=>handleChange("ltc",e.target.value)}
                  />
                </div>
              </div>

            </div>


            <div className="row">
              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="usdc"> USDC Address</label>
                  <input
                    type="text"
                    id="usdc"
                    name="usdc"
                    className="form-control input"
                    value={formdata.usdc}
                    onChange={(e)=>handleChange("usdc",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="ada"> Cardano (ADA) Address</label>
                  <input
                    type="text"
                    id="ada"
                    name="ada"
                    className="form-control input"
                    value={formdata.ada}
                    onChange={(e)=>handleChange("ada",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="pax"> PAX Address</label>
                  <input
                    type="text"
                    id="pax"
                    name="pax"
                    className="form-control input"
                    value={formdata.pax}
                    onChange={(e)=>handleChange("pax",e.target.value)}
                  />
                </div>
              </div>

            </div>


            <div className="row">
              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="aave"> AAVE Address</label>
                  <input
                    type="text"
                    id="aave"
                    name="aave"
                    className="form-control input"
                    value={formdata.aave}
                    onChange={(e)=>handleChange("aave",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="usdt"> USDT Address</label>
                  <input
                    type="text"
                    id="usdt"
                    name="usdt"
                    className="form-control input"
                    value={formdata.usdt}
                    onChange={(e)=>handleChange("usdt",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="xlm"> XLM Address</label>
                  <input
                    type="text"
                    id="xlm"
                    name="xlm"
                    className="form-control input"
                    value={formdata.xlm}
                    onChange={(e)=>handleChange("xlm",e.target.value)}
                  />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="bch"> BCH Address</label>
                  <input
                    type="text"
                    id="bch"
                    name="bch"
                    className="form-control input"
                    value={formdata.bch}
                    onChange={(e)=>handleChange("bch",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="shiba"> SHIBA Address</label>
                  <input
                    type="text"
                    id="shiba"
                    name="shiba"
                    className="form-control input"
                    value={formdata.shiba}
                    onChange={(e)=>handleChange("shiba",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="xrp"> XRP Address</label>
                  <input
                    type="text"
                    id="xrp"
                    name="xrp"
                    className="form-control input"
                    value={formdata.xrp}
                    onChange={(e)=>handleChange("xrp",e.target.value)}
                  />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="yfi"> YFI Address</label>
                  <input
                    type="text"
                    id="yfi"
                    name="yfi"
                    className="form-control input"
                    value={formdata.yfi}
                    onChange={(e)=>handleChange("yfi",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="dodge"> Doge Coin Address</label>
                  <input
                    type="text"
                    id="dodge"
                    name="dodge"
                    className="form-control input"
                    value={formdata.dodge}
                    onChange={(e)=>handleChange("dodge",e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-4 pt-3 pb-3">
                <div className="form-group">
                  <label htmlFor="sol">SOLANA Address</label>
                  <input
                    type="text"
                    id="sol"
                    name="sol"
                    className="form-control input"
                    value={formdata.sol}
                    onChange={(e)=>handleChange("sol",e.target.value)}
                  />
                </div>
              </div>
          

            </div>

            <button disabled={isSubmitting} className="btn btn-lg btn-primary btn-block" type="submit">{!isSubmitting?"Submit":"Submitting..."}</button>
          </form>
        }

            
        </div>
      </div>
    </AdminLayout>
  );
}

export default WalletAddress;
