import React from 'react'
import { displayAmount, makeTitleCase } from '../utils/helpers'

function BalanceCard({title, value}) {
 let val = typeof value === "string"? value : displayAmount(value, 2)
  return (
    <div className="col">
    <div className="mt-md-0 py-3 px-4 mx-2">
      <p className="text-white-50 mb-2 text-truncate">
        {makeTitleCase(title)}
      </p>
      <h3 className="text-white mb-0">
        {val}
      </h3>
    </div>
  </div>
  )
}

export default BalanceCard