import React from 'react'

function Reasons() {
  return (
    <div className="buy-sell-cryptocurrency-area bg-image pt-70">
    <div className="container">
      <div className="section-title">
        <h2>Why Choose Rapidus Invest</h2>
        <p>Discover why millions of users from over 140 countries choose to trade with Rapidus Invest.</p>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="buy-sell-cryptocurrency-image"><img src={require("../assets/images/women-with-tab.png")} alt="image" /></div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="buy-sell-cryptocurrency-content">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-sm-6 col-md-6">
                <div className="single-buy-sell-box">
                  <div className="icon"><img src={require("../assets/images/icon/icon12.png")} alt="image" /></div>
                  <h3>Regulated</h3>
                  <p>Our company is regulated by CySEC authority in the EU. It is authorized by the FCA in the UK, and by FinCEN in the United States, and by ASIC in Australia.</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6">
                <div className="single-buy-sell-box">
                  <div className="icon"><img src={require("../assets/images/icon/icon8.png")} alt="image" /></div>
                  <h3>Security</h3>
                  <p>Your funds are protected by industry-leading security protocols.</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6">
                <div className="single-buy-sell-box">
                  <div className="icon"><img src={require("../assets/images/icon/icon11.png")} alt="image" /></div>
                  <h3>Privacy</h3>
                  <p>We will never share your private data without your permission.</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6">
                <div className="single-buy-sell-box">
                  <div className="icon"><img src={require("../assets/images/icon/icon14.png")} alt="image" /></div>
                  <h3>Huge Returns</h3>
                  <p>The simple way to grow your money like the world’s most sophisticated investors.</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-md-6">
                <div className="single-buy-sell-box">
                  <div className="icon"><img src={require("../assets/images/icon/icon1.png")} alt="image" /></div>
                  <h3>Support</h3>
                  <p>Our people are available for you 24/7.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Reasons