import React, { useEffect, useState } from "react";
import { fetchWithdrawals } from "../api/admin.api";
import AdminLayout from "../components/layouts/AdminLayout";
import Loader from "../components/Loader";
import { displayAmount, makeTitleCase } from "../utils/helpers";

function AdminWithdrawals() {

    const [withdrawals, setWithdrawals] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null)


    const getWithdrawals = async () => {
        try{
            setLoading(true);
            const resp = await fetchWithdrawals();
            // console.clear()
            // console.log(resp)
            setLoading(false)
            if(!resp.status) return setErrors(resp.errors[0].msg);
            setWithdrawals(resp.value);
        }
        catch(err){
            console.log(err)
            setErrors("An error has occurred")
        }
    }
    useEffect(() => {
     getWithdrawals();
    }, [])
    
  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">

        <div className="col-lg-12">
            <div className="card">
              <div className="card-header justify-content-between d-flex align-items-center">
                <h4 className="card-title">Withdrawals Request</h4>
              </div>
              {/* end card header */}
              <div className="card-body table-responsive">
                <table className="table mb-0 hovered stripped ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Currency</th>
                      <th>Amount</th>
                      <th>Withdraw To</th>
                      <th>Wallet Address</th>
                      <th>Bank name</th>
                      <th>Account Number</th>
                      <th>City</th>
                      <th>Country</th>
                      <th>More Info</th>
                      <th>Withdrawal Date</th>
                      {/* <th colSpan={2}>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                      {
                          loading && <Loader/>
                      }
                      {
                          !loading && withdrawals && withdrawals.map((item, key)=><tr>
                            <th scope="row">{key + 1}</th>
                            <td>{makeTitleCase(item.name)}</td>
                            <td>{(item.currency)}</td>
                            <td>{displayAmount(item.amount, 2)}</td>
                            <td>{(item.withdrawalType)}</td>
                            <td>{(item.walletAddress)}</td>
                            <td>{(item.bankName)}</td>
                            <td>{(item.bankAccount)}</td>
                            <td>{(item.city)}</td>
                            <td>{(item.country)}</td>
                            <td>{(item.info)}</td>
                            <td>{new Date(item.created).toDateString()}</td>
                          </tr>) 
                      }
                    
                   
                  </tbody>
                </table>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>


        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminWithdrawals;
