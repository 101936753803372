import React from 'react'
import DashboardLayout from '../components/layouts/DashboardLayout'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

function Dashboard() {
  const watchlist = [
    "BINANCE:BTCUSD",
    "BINANCE:BTCUSDT",
    "BINANCE:ETHUSDT",
    "BINANCE:BNBUSDT",
    "BINANCE:XRPUSDT",
    "BINANCE:LTCUSDT",
    "BINANCE:DOGEUSDT",
    "COINBASE:BTCUSD",
    "COINBASE:ETHUSD",
    "COINBASE:ADAUSD",
    "COINBASE:LTCUSD",
    "COINBASE:DOGEUSD",
    "COINBASE:SOLUSD",
  ];

  return (
    <DashboardLayout>
      <AdvancedRealTimeChart theme="dark" width="100%" height={800} watchlist={watchlist} symbol="COINBASE:BTCUSD" details={true} hotlist={true} calendar={true}></AdvancedRealTimeChart>
     </DashboardLayout>
  )
}

export default Dashboard