import React from 'react'

function CreationProcess() {
  return (
  <div className="account-create-process-area ptb-100">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-8 col-lg-9 col-md-12">
        <div className="account-create-process-content">
          <div className="section-title">
            <h2>Get Started in a Few Minutes</h2>
            <p>Your total income directly depends on the amount you trade and invest, so the more you contribute, the more you can earn.</p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-process-box">
                <div className="icon"><img src={require("../assets/images/icon/icon1.png")} alt="image" /></div>
                <h3>Create Account</h3>
                <p>Create an account here with your email address.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-process-box">
                <div className="icon"><img src={require("../assets/images/icon/icon2.png")} alt="image" /></div>
                <h3>Fund to your wallet</h3>
                <p>Add coins to your wallet to activate copy trading.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-process-box">
                <div className="icon"><img src={require("../assets/images/icon/icon3.png")} alt="image" /></div>
                <h3>Copy your first trade</h3>
                <p>Each new trades will be mirrored in your account and profits will settle immediately.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-3 col-md-12">
        <div className="account-create-process-image text-center"><img src={require("../assets/images/convert-currency.png")} alt="image" /></div>
      </div>
    </div>
  </div>
</div>
  )
}

export default CreationProcess