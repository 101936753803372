import axios from "axios";
import { API_URL } from "../configs/app";


export const loginApi = async data => {
    try{
const res = await axios.post(`${API_URL}/auth/login`, data)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const registerApi = async data => {
    try{
const res = await axios.post(`${API_URL}/auth/register`, data)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}