import React from 'react'
import { Link } from 'react-router-dom'
import CreationProcess from '../components/CreationProcess'
import WebsiteLayout from '../components/layouts/WebsiteLayout'
const jumboImage = require("../assets/images/blog/blog1.jpg")
function About() {
  return (<WebsiteLayout>
     <div>
  <div className="about-area pb-100">
    <div className="container">
      <div className="about-content" style={{backgroundImage:`url(${jumboImage})`}}>
        <div className="content">
          <h1>We’re empowering people to achieve greatness</h1>
          <p>At Rapidus Invest, we have been continuously transforming the traditional money management industry, in order to open the financial markets to everyone, everywhere. We are a leader in the global Fintech revolution. We are the leading social trading network, with thoudsands of registered users and an array of innovative trading and investment tools.</p>
        </div>
      </div>
      <div className="history-timeline timeline">
        <div className="section-title"><h2>About Us</h2></div>
        <div className="timeline-event">
          <div className="timeline-event-icon"><i className="bx bx-coin-stack" /></div>
          <div className="timeline-event-date bg1"></div>
          <div className="timeline-event-content">
            <h3>What is Rapidus Invest?</h3>
            <p>
            Rapidus Invest is a copy trading investment platform based on the cooperation between Investors and Traders. Investors copy successful Traders' transactions, and Traders, in return, receive commission from Investors subscribed to their accounts. Rapidus Invest places no restrictions on the role you may choose in the system: all the traders of Forex market may operate both as Investors and as Traders
            </p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-event-icon"><i className="bx bx-sun" /></div>
          <div className="timeline-event-date bg2"></div>
          <div className="timeline-event-content">
            <h3>Why Rapidus Invest?</h3>
            <p>
            <ul>
              <li>A transparent and understandable operation scheme for Investors and Traders.</li>
              <li>An opportunity to copy transactions from a Trader's account without installing trading terminal.</li>
              <li>Flexible customization of parameters of copying transactions from many Traders' accounts.</li>
              <li>An opportunity to independently cancel the copying of transactions from a Trader's account.</li>
              <li>An opportunity to use Mechanical Trading Systems (MTS) when trading on Traders' accounts.</li>
              <li>Wide choice of payment systems to deposit/withdraw funds.</li>
              <li>24/7 Online Live Support.</li>
            </ul>
            </p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-event-icon"><i className="bx bxl-apple" /></div>
          <div className="timeline-event-date bg3"></div>
          <div className="timeline-event-content">
            <h3>Secure and Stable</h3>
            <p>
            Your online account supports 2FA. Only authenticated users can login to your account.
            </p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-event-icon"><i className="bx bxl-bitcoin" /></div>
          <div className="timeline-event-date bg4"></div>
          <div className="timeline-event-content">
            <h3>Fast Transaction</h3>
            <p>
            Your transaction is approved at first confirmation.
            </p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-event-icon"><i className="bx bxs-pie-chart-alt" /></div>
          <div className="timeline-event-date bg5"></div>
          <div className="timeline-event-content">
            <h3>Huge Returns on Investments</h3>
            <p>
            Invest, sit back and watch your money grow.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreationProcess />

  <div className="cta-area pt-100 undefined">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 col-md-12">
          <div className="cta-content undefined">
            <h2>Start Trading on Rapidus Invest</h2>
            <p>We've helped over 10,000 people discover smarter investing in multiple types of assets. Build your future.</p>
            <Link className="default-btn global-cursor" to="/register"><i className="bx bxs-rocket" /> Get Started</Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="cta-image"><img src={require("../assets/images/man.png")} alt="image" /></div>
        </div>
      </div>
    </div>
    <div className="shape6"><img src={require("../assets/images/shape/shape6.png")} alt="image" /></div>
    <div className="shape7"><img src={require("../assets/images/shape/shape7.png")} alt="image" /></div>
    <div className="shape8"><img src={require("../assets/images/shape/shape8.png")} alt="image" /></div>
    <div className="shape9"><img src={require("../assets/images/shape/shape9.png")} alt="image" /></div>
    <div className="shape15"><img src={require("../assets/images/shape/shape15.png")} alt="image" /></div>
  </div>
</div>

    </WebsiteLayout>
  )
}

export default About