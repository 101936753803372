import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showAlert } from "../utils/helpers";
import { loginApi } from "../api/auth.api";
import { cookieGet, cookieStore } from "../utils/cookie";

function Login() {
  const history = useNavigate();
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (field, value) => {
    setFormdata({
      ...formdata,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const resp = await loginApi(formdata);
      setLoading(false);
      if (!resp.status) {
        setError(resp.errors);
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }

      if (resp.value.role === "user") {
        //store to cookie
        cookieStore("moonpy_token", resp.value.token);
        //redirect
        history("/dashboard");
      } else if(resp.value.role === "admin"){
        //store to cookie
        cookieStore("moonpy_admin_token", resp.value.token);
        //redirect
        history("/admin-dashboard");
      }
    } catch (err) {
      return showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const checkForToken = async () => {
    let token = await cookieGet("moonpy_token");
    if (token) return history("/dashboard");
  };
  useEffect(() => {
    checkForToken();
  }, []);

  return (
    // <WebsiteLayout>
    <div className="profile-authentication-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <div className="row"> */}
            <div className="col-lg-6 col-lg-pull-3 col-md-6 col-md-pull-3 col-sm-12">
              <div className="login-form">
                <h2>Login to Rapidus Invest</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      value={formdata.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      disabled={loading}
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      value={formdata.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      disabled={loading}
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          defaultValue
                          id="rememberMe"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberMe"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                      <Link
                        to="/forgot-password"
                        className="lost-your-password"
                      >
                        Lost your password?
                      </Link>
                    </div>
                  </div>
                  <button type="submit" disabled={loading}>
                    {!loading ? "Login" : "Loading..."}
                  </button>
                </form>
                <div className="row align-items-center pt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                    {/* <div className="form-check"><input type="checkbox" className="form-check-input" defaultValue id="rememberMe" /><label className="form-check-label" htmlFor="rememberMe">Remember me</label></div> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                    <Link to="/register" className="lost-your-password">
                      Dont have an account? Register
                    </Link>
                  </div>
                </div>
                {/* <div className="login-with-button">
                <button type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" aria-hidden="true">
                    <title>Google</title>
                    <g fill="none" fillRule="evenodd">
                      <path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z" />
                      <path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z" />
                      <path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z" />
                      <path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z" />
                    </g>
                  </svg>
                  Login with Google
                </button>
              </div> */}
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
      <Link className="back-icon" to="/">
        <i className="bx bx-x" />
      </Link>
    </div>
    // </WebsiteLayout>
  );
}

export default Login;
