import React, { useEffect, useState } from "react";
import { fetchUser, fetchUserBalance } from "../api/user.api";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";
import Loader from "../components/Loader";
import DashboardLayout from "../components/layouts/DashboardLayout";

function Profile() {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [error, setError] = useState(null);

  const getUserDetails = async () => {
    try {
      setLoading(true);
      setError(null);
      let resp = await fetchUser();
      setLoading(false);
      if (resp.errors) {
        setError(resp.errors.msg);
        return false;
      }
      //   console.log(resp.value);
      setUser(resp.value);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError("An error has occured ");
    }
  };
  const getUserBalance = async () => {
    try {
      console.clear();
      setLoadingBalance(true);
      const resp = await fetchUserBalance();
      setLoadingBalance(false);
      if (resp.errors) {
        setError(resp.errors[0].msg);
        return false;
      }
      setBalance(resp.value);
    } catch (err) {
      setError("An error has occured ");
    }
  };

  useEffect(() => {
    if (error && error != null) showAlert({
        title:"Attention!",msg: error});
  }, [error]);

  useEffect(() => {
    getUserDetails();
    getUserBalance();
  }, []);

  let name = user ? makeTitleCase(user.name) : " ";
  return (
    <DashboardLayout>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="user-profile-img">
                      <img
                        src="assets/images/pattern-bg.jpg"
                        className="profile-img profile-foreground-img rounded-top"
                        style={{ height: 120 }}
                        alt
                      />
                      <div className="overlay-content rounded-top">
                        <div>
                          <div className="user-nav p-3"></div>
                        </div>
                      </div>
                    </div>
                    {/* end user-profile-img */}
                    <div className="mt-3 position-relative">
                      <div className="text-center">
                        <img
                          src={require("../assets/images/user/user.png")}
                          alt
                          className="avatar-xl rounded-circle img-thumbnail"
                        />
                        <div className="mt-3">
                          <h5 className="mb-1">{name}</h5>
                          <h6 className="mb-5">{user ? user.email : ""}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card body */}
                </div>
              </div>

              <div className="col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-3">Profile</h5>
                    <div className="mt-3">
                      <p className="font-size-15 mb-1">Name: {name}</p>
                      <p className="font-size-15">
                        Email: {user ? user.email : ""}
                      </p>
                      <p className="font-size-15">
                        Phone: {user ? user.phone : ""}
                      </p>
                    </div>
                    <div className="mt-4 pt-3">
                      <h5 className="card-title mb-4">Wallet Balance</h5>
                      <div className="table-responsive">
                        <table className="table table-nowrap table-hover mb-1">
                          <thead className="bg-light">
                            <tr>
                              <th scope="col">Currency</th>
                              <th scope="col">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                     <tr><th scope="row">Bitcoin</th><td> {displayAmount(balance?balance.btc:0,2)} </td></tr>
                     <tr><th scope="row">Ethereum</th><td> {displayAmount(balance?balance.eth:0,2)} </td></tr>
                     <tr><th scope="row">Litecoin</th><td> {displayAmount(balance?balance.ltc:0,2)} </td></tr>
                     <tr><th scope="row">Bitcoin Cash</th><td> {displayAmount(balance?balance.bch:0,2)} </td></tr>
                     <tr><th scope="row">Ripple</th><td> {displayAmount(balance?balance.xrp:0,2)} </td></tr>
                     <tr><th scope="row">Cardano</th><td> {displayAmount(balance?balance.ada:0,2)} </td></tr>
                     <tr><th scope="row">Tether</th><td> {displayAmount(balance?balance.usdt:0,2)} </td></tr>
                     <tr><th scope="row">Shiba Inu</th><td> {displayAmount(balance?balance.shiba:0,2)} </td></tr>     
                     <tr><th scope="row">USDC</th><td> {displayAmount(balance?balance.usdc:0,2)} </td></tr>     
                     <tr><th scope="row">YFI</th><td> {displayAmount(balance?balance.yfi:0,2)} </td></tr>     
                     <tr><th scope="row">Doge</th><td> {displayAmount(balance?balance.dodge:0,2)} </td></tr>     
                     <tr><th scope="row">AAVE</th><td> {displayAmount(balance?balance.aave:0,2)} </td></tr>     
                     <tr><th scope="row">XLM</th><td> {displayAmount(balance?balance.xlm:0,2)} </td></tr>     
                     <tr><th scope="row">SOL</th><td> {displayAmount(balance?balance.sol:0|0,2)} </td></tr>     
                     {/* <tr><th scope="row">Portfolio Signal</th><td> {(balance?balance.portfolio:0,2)} </td></tr>     
                     <tr><th scope="row">Tax</th><td> {(balance?balance.tax:0,2)} </td></tr>     
                     <tr><th scope="row">Portfolio Increase</th><td> {(balance && balance.increase ? `${balance.increase}%`: "0%")} </td></tr>      */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
    </DashboardLayout>
  );
}

export default Profile;
