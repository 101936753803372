import React from 'react'
import { Link } from 'react-router-dom'

function Jumbotron() {
  return (
    <div className="main-banner-area">
    <div className="container">
      
      <div className="row align-items-center m-0">
        <div className="col-xl-6 col-lg-6 col-md-12 p-0">
          <div className="main-banner-content">
            <h1>Trade &amp; Invest Crypto Easy, Fast and Secure!</h1>
            <p>
            We've helped over 10,000 people discover smarter investing in multiple types of assets. Build your future.
            </p>
            <Link className="default-btn" to="/register"><i className="bx bxs-rocket" /> Get Started Now</Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-12 col-md-12 p-0">
          <div className="main-banner-image"><img src={require("../assets/images/banner/banner-img1.png")} alt="image" /></div>
        </div>
      </div>
    </div>
    <div className="shape1"><img src={require("../assets/images/shape/shape1.png")} alt="image" /></div>
    <div className="shape2"><img src={require("../assets/images/shape/shape2.png")} alt="image" /></div>
    <div className="shape3"><img src={require("../assets/images/shape/shape3.png")} alt="image" /></div>
    <div className="shape5"><img src={require("../assets/images/shape/shape5.png")} alt="image" /></div>
    <div className="shape9"><img src={require("../assets/images/shape/shape9.png")} alt="image" /></div>
  </div>
  )
}

export default Jumbotron