import React from 'react'
import { makeTitleCase } from '../utils/helpers'

function AddressTile({title, image, value, tag}) {
  return (
    <div className="col-xl-4 col-sm-6">
    <div className="card">
      <div className="card-body p-4">
        <div className="d-flex align-items-start">
          <div className="flex-shrink-0 avatar rounded-circle me-3">
            <img
              src={image}
              alt
              className="img-fluid rounded-circle"
            />
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="font-size-15 mb-1 text-truncate">
              <a
                href="pages-profile.html"
                className="text-dark"
              >
                {makeTitleCase(title)}
              </a>
            </h5>
            <span className="badge badge-soft-success mb-0">
              {tag}
            </span>
          </div>
      
        </div>
        <p className="mt-4 text-muted">
         {value}
        </p>
      </div>
      {/* end card body */}
    </div>
    {/* end card */}
  </div>
  )
}

export default AddressTile