import axios from "axios";
import { API_URL } from "../configs/app";
import { setHeader } from "../utils/helpers";


export const fetchUser = async () => {
    try{
        let header = await setHeader();
const res = await axios.get(`${API_URL}/users/details`, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchUserBalance = async () => {
    try{
        let header = await setHeader();
        const res = await axios.get(`${API_URL}/users/balance`, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchAddress = async () => {
    try{
        let header = await setHeader();
        const res = await axios.get(`${API_URL}/users/address`, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const submitWithdrawal = async data => {
    try{
        let header = await setHeader();
        const res = await axios.post(`${API_URL}/users/withdraw`, data, header)
        return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}



export const createDeposit = async data => {
    try{
        let header = await setHeader();
const res = await axios.post(`${API_URL}/users/deposit`, data, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}