import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerApi } from "../api/auth.api";
import { showAlert } from "../utils/helpers";
import { cookieStore } from "../utils/cookie";

function Register() {
  const history = useNavigate();
  const [formdata, setFormdata] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const resp = await registerApi(formdata);
      // console.log(resp);
      setLoading(false);
      if (!resp.status) {
        setError(resp.errors)
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      //store to cookie
      cookieStore("moonpy_token", resp.value);
      //redirect
      history("/dashboard");
    } catch (err) {
      // console.log(err);
      setLoading(false);
      setError({
        err,
      });
      return showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const handleChange = (field, value) => {
    setFormdata({
      ...formdata,
      [field]: value,
    });
  };

  return (
    <div className="profile-authentication-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <div className="row"> */}
            <div className="col-lg-6 col-md-12">
              <div className="register-form">
                <h2>Create an account</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      disabled={loading}
                      value={formdata.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      className="form-control"
                      placeholder="Full Name"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      disabled={loading}
                      value={formdata.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      disabled={loading}
                      value={formdata.phone}
                      onChange={(e) => handleChange("phone", e.target.value)}
                      className="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      disabled={loading}
                      value={formdata.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                  <button type="submit" disabled={loading}>{!loading?"Register":"Loading..."}</button>
                </form>

                <div className="row align-items-center pt-4">
                  <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
              
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                    <Link to="/login" className="lost-your-password">
                      Already have an account? Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
      <Link className="back-icon" to="/">
        <i className="bx bx-x" />
      </Link>
    </div>
  );
}

export default Register;
