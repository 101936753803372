import React, { useEffect, useState } from "react";
import { submitWithdrawal } from "../api/user.api";
import DashboardLayout from "../components/layouts/DashboardLayout";
import { showAlert } from "../utils/helpers";

function Withdraw() {
    let initialState = { amount:"", currency:"BTC", withdrawalType:"wallet", destinationWallet:"", bankName:"", bankAccount:"", city:"", country:"", info:"" }
    const [formdata, setFormdata] = useState(initialState)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errors, setErrors] = useState(null)

    const handleChange = (field, value) => {
            setFormdata({
                ...formdata,
                [field]: value
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
            try{
                setIsSubmitting(true);
                setErrors(null)
                console.log(formdata)
                let resp = await submitWithdrawal(formdata)
                setIsSubmitting(false)
                console.log(resp)
                if(!resp.status) {
                    setErrors(resp.errors[0].msg)
                    return;
                }
                setFormdata(initialState);
                return showAlert({
                    title: "Withdrawal Successful!",
                    msg: "Your withdrawal request has been received successfully and is being proccessed.",
                    icon: "success",
                    btnText: "Close",
                  });
            }
            catch(err){
                console.log(err)
                return setErrors("A error has occurred");
            }
    }


  useEffect(() => {
    if (errors && errors != null) showAlert({
        title:"Attention!",msg: errors});
  }, [errors]);

  return (
    <DashboardLayout>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="mb-0 pt-5 pb-5 text-decoration-underline">
              Withdraw
            </h2>
            <div id="addproduct-accordion" className="custom-accordion">
              <div className="card">
                <a
                  href="#addproduct-billinginfo-collapse"
                  className="text-dark"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="addproduct-billinginfo-collapse"
                >
                  <div className="p-4">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-16 mb-1">
                          Fill the form to request for withdrawal
                        </h5>
                      </div>
                      <div className="flex-shrink-0">
                        <i className="mdi mdi-chevron-up accor-down-icon font-size-24" />
                      </div>
                    </div>
                  </div>
                </a>
                <div
                  id="addproduct-billinginfo-collapse"
                  className="collapse show"
                  data-bs-parent="#addproduct-accordion"
                >
                  <div className="p-4 border-top">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="amount">
                          How much are you withdrawing?
                        </label>
                        <input
                          id="amount"
                          name="amount"
                          placeholder="Enter Amount"
                          type="number"
                          className="form-control"
                          value={formdata.amount}
                          onChange={(e)=>handleChange("amount", e.target.value)}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="currencyType"
                              className="form-label"
                            >
                              Choose Currency
                            </label>
                            <select
                              className="form-control"
                              data-trigger
                              name="currencyType"
                              id="currencyType"
                              value={formdata.currency}
                          onChange={(e)=>handleChange("currency", e.target.value)}
                            >
                              <option value="BTC">Bitcoin</option>
                              <option value="ETH">Ethereum</option>
                              <option value="BCH">Bitcoin Cash</option>
                              <option value="LTC">Litecoin</option>
                              <option value="XRP">XRP</option>
                              <option value="ADA">Cardano</option>
                              <option value="USDT">Tether</option>
                              <option value="USDC">USD Coin</option>
                              <option value="SHIBA">SHIBA INU</option>
                              <option value="DODGE">DOGE Coin</option>
                              <option value="SOL">SOLANA</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="withdrawalType"
                              className="form-label"
                            >
                              Where are you withdrawing to?
                            </label>
                            <select
                              className="form-control"
                              data-trigger
                              name="withdrawalType"
                              id="withdrawalType"
                              value={formdata.withdrawalType}
                          onChange={(e)=>handleChange("withdrawalType", e.target.value)}
                            >
                              <option value="wallet">Wallet</option>
                              <option value="bank">Bank Account</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className={`mb-3 ${formdata.withdrawalType != "wallet"?"hidden":null}`}>
                        <label className="form-label" htmlFor="walletAddress">
                          Destination Wallet Address
                        </label>
                        <input
                          id="walletAddress"
                          name="walletAddress"
                          placeholder="Enter your wallet address"
                          type="text"
                          className="form-control"
                          value={formdata.destinationWallet}
                          onChange={(e)=>handleChange("destinationWallet", e.target.value)}
                        />
                      </div>

                      <div className={`row ${formdata.withdrawalType != "bank"?"hidden":null}`}>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="bankName"
                            >
                              Bank Name
                            </label>
                            <input
                              id="bankName"
                              name="bankName"
                              placeholder="Enter Bank Name"
                              type="text"
                              className="form-control"
                              value={formdata.bankName}
                              onChange={(e)=>handleChange("bankName", e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="accountNumber"
                            >
                              Bank Account Number
                            </label>
                            <input
                              id="accountNumber"
                              name="accountNumber"
                              placeholder="Enter Bank Account Number"
                              type="text"
                              className="form-control"
                              value={formdata.bankAccount}
                              onChange={(e)=>handleChange("bankAccount", e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="city"
                            >
                              City
                            </label>
                            <input
                              id="city"
                              name="city"
                              placeholder="Enter City"
                              type="text"
                              className="form-control"
                              value={formdata.city}
                              onChange={(e)=>handleChange("city", e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="country"
                            >
                              Country
                            </label>
                            <input
                              id="country"
                              name="country"
                              placeholder="Enter Country"
                              type="text"
                              className="form-control"
                              value={formdata.country}
                              onChange={(e)=>handleChange("country", e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label" htmlFor="info">
                          Additional information
                        </label>
                        <textarea
                          className="form-control"
                          id="info"
                          placeholder="Enter Additional Information"
                          rows={4}
                          value={formdata.info}
                          onChange={(e)=>handleChange("info", e.target.value)}
                        />
                      </div>

                      <button type="submit" disabled={isSubmitting} className="btn btn-block btn-lg btn-primary">{
                          !isSubmitting?"Submit":"Submitting..."
                      }</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Withdraw;
