import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return ( 
<footer className="footer-area">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-4 col-sm-6 col-md-6">
          <div className="single-footer-widget">
              <div  style={{display:"flex",alignItems:"center"}}>
            <img src={require("../assets/images/mplogo.png")} alt="logo" />
            <Link to="/" className="d-inline-block logo"> <span style={{fontSize:30,fontWeight:"bold"}}>Rapidus Invest</span>  </Link>
            </div>
            <div className="newsletter-form">
              <p>SUBSCRIBE TO OUR NEWSLETTER</p>
              <form data-toggle="validator">
                <input type="email" className="input-newsletter" placeholder="Enter your email" name="EMAIL" required autoComplete="off" /><button type="submit">Subscribe Now <i className="bx bx-paper-plane" /></button>
                <div id="validator-newsletter" className="form-result" />
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-sm-6 col-md-6">
          <div className="single-footer-widget">
            <h3>Start Trading</h3>
            <ul className="services-links">
              <li><Link to="/dashboard/trade/btc">Bitcoin</Link></li>
              <li><Link to="/dashboard/trade/eth">Ethereum</Link></li>
              <li><Link to="/dashboard/trade/xrp">Ripple</Link></li>
              <li><Link to="/dashboard/trade/ltc">Litecoin</Link></li>
              <li><Link to="/dashboard/trade/ste">Stellar</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-md-6">
          <div className="single-footer-widget pl-5">
            <h3>Links</h3>
            <ul className="quick-links">
              <li><Link to="/login">Login</Link></li>
              <li><Link to="/register">Register</Link></li>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/profile">Profile</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-md-6">
          <div className="single-footer-widget">
            <h3>Contact Info</h3>
            <ul className="footer-contact-info">
              <li>Address: 93 Green Lane Cambridge, CB78 9JX, United Kingdom</li>
              <li>
                Email: <a href="mailto:support@rapidusinvest.com"><span className="" >support@rapidusinvest.com</span></a>
              </li>
              {/* <li>Phone: <a href="tel:+44587154756">+44 1223 926945</a></li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <div className="container" style={{display:"flex",alignItems:"center",justifyContent:"space-between", flexWrap:"wrap"}}>
        <p>
          Copyright {new Date().getFullYear()} <strong>RapidusInvest.com</strong>. All Rights Reserved
        </p>
        <div>
            <Link to="/privacy">Privacy Policy  </Link>
            
            <Link to="/terms">  Terms of Service</Link>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer