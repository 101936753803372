import React from 'react'

function FunFacts() {
  return (
  <div className="funfacts-area pt-100">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-3 col-md-3 col-sm-6 col-6">
        <div className="single-funfacts-box">
          <div className="icon"><img src={require("../assets/images/icon-bg.png")} alt="image" /><img src={require("../assets/images/icon/icon8.png")} className="main-icon" alt="image" /></div>
          <h3>Secured Investing</h3>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 col-6">
        <div className="single-funfacts-box">
          <div className="icon"><img src={require("../assets/images/icon-bg.png")} alt="image" /><img src={require("../assets/images/icon/icon9.png")} className="main-icon" alt="image" /></div>
          <h3> Thousands of Happy Users</h3>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 col-6">
        <div className="single-funfacts-box">
          <div className="icon"><img src={require("../assets/images/icon-bg.png")} alt="image" /><img src={require("../assets/images/icon/icon11.png")} className="main-icon" alt="image" /></div>
          <h3>Global Network</h3>
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-6 col-6">
        <div className="single-funfacts-box">
          <div className="icon"><img src={require("../assets/images/icon-bg.png")} alt="image" /><img src={require("../assets/images/icon/icon3.png")} className="main-icon" alt="image" /></div>
          <h3>Millions in Volume</h3>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default FunFacts