import React from 'react'

function Feedback() {
  return (
  <div className="fedback-area pt-100 pb-70">
  <div className="container">
     <div className="section-title">
      <h2>The Most Trusted Copy Trading Platform</h2>
      <p>Whether you're a beginner learning the basics or you simply don't have time to watch the markets, now it's easy to leverage other traders expertise. With our CopyTrader, you can automatically copy top-performing traders, instantly replicating their trading in your own portfolio.</p>
    </div>

    <div className="row justify-content-center">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="single-feedback-box">
          <div className="rating">
            <img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" />
            <img src={require("../assets/images/star.png")} alt="image" />
          </div>
          <p> With Rapidus Invest running my portfolio, I've been to focus on expanding business. It's a big plus.</p>
          <div className="client-info">
            <div className="d-flex align-items-center">
              <img src={require("../assets/images/user/user1.jpg")} alt="image" />
              <div className="title">
                <h3>Thomas Malan</h3>
                <span>Jeweller</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="single-feedback-box">
          <div className="rating">
            <img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" />
            <img src={require("../assets/images/star.png")} alt="image" />
          </div>
          <p>
          I have been using the platform for months and I am quiet satisfied. I love the social trading options and the clean interface.
          </p>
          <div className="client-info">
            <div className="d-flex align-items-center">
              <img src={require("../assets/images/user/user2.jpg")} alt="image" />
              <div className="title">
                <h3>Sarah Taylor</h3>
                <span>Designer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="single-feedback-box">
          <div className="rating">
            <img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" /><img src={require("../assets/images/star.png")} alt="image" />
            <img src={require("../assets/images/star.png")} alt="image" />
          </div>
          <p>
          CINM is very happy to have Rapidus Invest as our trustworthy partner. We have already recommended Rapidus Invest to thousands of our existing customers who are happy and satisfied with their excellent services.
          </p>
          <div className="client-info">
            <div className="d-flex align-items-center">
              <img src={require("../assets/images/user/user3.jpg")} alt="image" />
              <div className="title">
                <h3>Danny Santos</h3>
                <span>Account Professional</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Feedback