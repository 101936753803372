import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteUser, getAllUsers } from "../api/admin.api";
import AdminLayout from "../components/layouts/AdminLayout";
import Loader from "../components/Loader";
import { makeTitleCase, showAlert } from "../utils/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);


function AdminDashboard() {

    const [users, setUsers] = useState([])
    const [isGettingUsers, setIsGettingUsers] = useState(false)
    const [userError, setUserError] = useState(null)

    const getUsers =  async () => {
        try{
            setIsGettingUsers(true);
            setUserError(null)
            const resp = await getAllUsers();
            setIsGettingUsers(false);
            console.log(resp)
            if(!resp.status){ setUserError(resp.errors[0].msg); return;}
            setUsers(resp.value)
        }
        catch(err){
            console.log(err);
            setUserError("An error has occurred, try again.")
        }
    }
    const removeUser = async (id)=>{
      let confirmation = window.confirm("Are you sure you want to delete this user?!");
      try{
        if(!confirmation)return;
        let resp = await deleteUser(id);
        if(!resp.status) { setUserError("Unable to delete this user, try again."); return;}
        return showAlert({
          title: "Deleted!",
          msg:"User has been deleted successfully, Refresh the page to see changes",
          icon: "success",
          btnText: "Close",
        });
      }
      catch(err){
        console.log(err);
        setUserError("An error has occurred, try again.")
      }
     
    }
    useEffect(() => {
      getUsers()
    }, [])
    
  return (
    <AdminLayout>
      <div className="container-fluid">
        <div className="row">

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header justify-content-between d-flex align-items-center">
                <h4 className="card-title">Registered Users</h4>
              </div>
              {/* end card header */}
              <div className="card-body table-responsive">
                <table className="table mb-0 hovered stripped ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Level</th>
                      <th>Status</th>
                      <th>Date Joined</th>
                      <th colSpan={3}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                      {
                          isGettingUsers && <Loader/>
                      }
                      {
                          !isGettingUsers && users && users.map((user, key)=><tr>
                            <th scope="row">{key + 1}</th>
                            <td>{makeTitleCase(user.name)}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.level}</td>
                            <td>{user.status}</td>
                            <td>{new Date(user.created).toDateString()}</td>
                            <td>
                                <Link to={`/user-update/${user.uid}`} className="btn btn-primary btn-sm"> Update Balance   </Link>
                                <button onClick={()=>removeUser(user._id)} className="btn btn-danger btn-sm"> Delete User   </button>
                            </td>
                          </tr>) 
                      }
                    
                   
                  </tbody>
                </table>
              </div>
              {/* end card body */}
            </div>
            {/* end card */}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default AdminDashboard;
